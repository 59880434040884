/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
#home {
  float: left;
  margin-bottom: 20px;
}
.desk {
  width: 92%;
  margin: 0 auto;
}
#find {
  display: block;
}
.cb-layout2 #subnavi {
  display: block;
}
.cb-cart-text-holder {
  display: block;
}
#header .mail {
  display: none;
}
#expo div.link {
  bottom: 20px;
}
#find {
  position: absolute;
  right: 0;
  bottom: 20px;
  margin-top: 0;
  margin-left: 0;
  width: auto;
  margin-right: 0;
}
#find .find {
  position: static;
  float: right;
  padding-top: 0;
  padding-bottom: 0;
  height: 27px;
}
#searchfield {
  width: 200px;
  padding: 6px 10px;
}
#content {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
#maincontent {
  width: 101.42566191%;
  margin-left: -0.70281124%;
}
.cb-layout2 #maincontent {
  float: right;
  margin-right: -1.01832994%;
  width: 73.3197556%;
  margin-left: 0;
}
#head {
  width: 98.59437751%;
  margin-left: 0.70281124%;
  margin-right: 0.70281124%;
  margin-top: 80px;
}
.vcard {
  width: 255px;
  text-align: left;
}
.copyright {
  text-align: left;
}
.mobile-phone {
  top: 48px;
  right: 0;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 0.70281124%;
  margin-left: 0.70281124%;
}
.area .part,
.area > .grid table {
  margin-right: 0.70281124%;
  margin-left: 0.70281124%;
  width: 98.59437751%;
}
.area .tiny {
  width: 48.59437751%;
}
.area > .slim {
  width: 33.33333333%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 2.10843373%;
  margin-left: 2.10843373%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 95.78313253%;
}
.area > .slim .tiny {
  width: 95.78313253%;
}
.cb-layout2 .area {
  width: 100%;
}
.cb-layout2 .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .area h2,
.cb-layout2 .area .foot {
  margin-right: 1.38888889%;
  margin-left: 1.38888889%;
}
.cb-layout2 .area .part,
.cb-layout2 .area > .grid table {
  margin-right: 1.38888889%;
  margin-left: 1.38888889%;
  width: 97.22222222%;
}
.cb-layout2 .area .tiny {
  width: 47.22222222%;
}
.cb-layout2 .area > .slim .part,
.cb-layout2 .area > .slim.grid table {
  width: 97.22222222%;
}
.cb-layout2 .area > .slim .tiny {
  width: 30.55555556%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
.main {
  width: 100%;
}
/* SEAM */
.cb-layout1 div.wide.seam {
  margin-left: 0.70281124%;
  margin-right: 0.70281124%;
  width: 98.59437751%;
}
.cb-layout1 div.wide.seam div.head h2,
.cb-layout1 div.wide.seam div.part {
  margin-left: 2.03665988%;
  margin-right: 2.03665988%;
}
.cb-layout1 div.wide.seam div.head h2.tall,
.cb-layout1 div.wide.seam div.part.tall {
  width: 95.92668024%;
}
.cb-layout1 div.wide.seam div.head h2.tiny,
.cb-layout1 div.wide.seam div.part.tiny {
  width: 45.92668024%;
}
.cb-layout1 div.slim.seam {
  margin-left: 0.70281124%;
  margin-right: 0.70281124%;
  width: 31.92771084%;
}
.cb-layout1 div.slim.seam div.head h2,
.cb-layout1 div.slim.seam div.part {
  margin-left: 6.28930818%;
  margin-right: 6.28930818%;
  width: 87.42138365%;
}
.cb-layout1 div.flat {
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  border-top: none;
  border-bottom: none;
  padding: 0;
}
.cb-layout2 div.main div.slim div.tall {
  width: 63.88888889%;
}
.cb-layout1 h1 {
  border-top: none;
  padding-top: 0;
}
div.wide h2 {
  border-top: none;
  padding-top: 25px;
  margin-left: 7px;
  margin-right: 7px;
  padding-left: 0;
  padding-right: 0;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 10% !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
#disp.shop,
#disp.cb-shoppingcart {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/******* module-shop-medium.less 2013-1-16 *******/
.cb-shop-tabs > li {
  display: inline-block;
  margin-bottom: -1px;
}
.cb-shop-tab {
  margin-right: 0.3em;
  border-bottom-width: 1px;
  border-radius: 4px 4px 0 0;
}
.cb-shop-tab.selected {
  border-bottom-color: #f5f5f5;
}
#disp.shop .name,
a.agbs,
.cb-shop-country > label {
  width: 37%;
}
#disp.shop .file,
#disp.shop .text,
#disp.shop select {
  width: 62%;
}
#disp.shop .zip {
  clear: none;
  width: 10%;
}
#disp.shop input.city {
  width: 51%;
}
#disp.shop .cb-shop-currency > select {
  width: 55%;
}
.cb-shop-currency > select + a {
  width: 6%;
}
.cb-shop-currency + fieldset > .name + a,
.cb-shop-vat + fieldset > .name + a {
  clear: none;
  margin-bottom: 0.5em;
}
#disp .cb-shop-country > select {
  width: 62%;
}
.cb-shoppingcart .bill,
.cb-shoppingcart .ship,
.cb-shoppingcart fieldset.tiny {
  float: left;
  width: 49%;
}
.cb-shoppingcart .bill,
.cb-shoppingcart fieldset.tiny {
  margin-right: 2%;
}
/*# sourceMappingURL=./screen-medium.css.map */